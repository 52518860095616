<div class="center-content">
  <!-- <div *ngIf="!publicView" class="center-content"> -->
  <button class="button-aliceblue" mat-stroked-button *ngIf="!addAlbumMode" (click)="onEdit()">edit</button>&nbsp;
  <button class="button-aliceblue" mat-stroked-button *ngIf="isEditMode || addAlbumMode" (click)="selectAll()">select
    all</button>&nbsp;
  <button class="button-aliceblue" mat-stroked-button *ngIf="isEditMode" (click)="moveToAlbum()">move to
    album</button>&nbsp;
  <!-- <button class="button-aliceblue" mat-stroked-button *ngIf="isEditMode || addAlbumMode" (click)="deselectAll()">deselect all</button>&nbsp; -->
  <button class="button-aliceblue" mat-stroked-button (click)="sort()">sort</button>&nbsp;
  <button class="button-aliceblue" mat-stroked-button *ngIf="addAlbumMode" (click)="addPhotoIdsToAlbum()">add to
    album</button>
  <!-- <button class="button-aliceblue" mat-stroked-button *ngIf="isEditMode && !addAlbumMode" (click)="makePublic()">make public</button>&nbsp;
  <button class="button-aliceblue" mat-stroked-button *ngIf="isEditMode && !addAlbumMode" (click)="hide()">hide from public</button>&nbsp; -->
  <button class="button-aliceblue border-danger" mat-stroked-button *ngIf="isEditMode && !addAlbumMode"
    (click)="delete()">delete</button>
  <!-- <button class="button-aliceblue" mat-stroked-button (click)="googleSync()">google-sync</button>&nbsp; -->
</div>

<div class="center-content">
  {{albumTitle}}
</div>
<span style="padding-left: 10%;size: 20px; color: #2d2d2d;"><b>Photos {{photos.length}}</b></span>
<div class="row center-content">
  <div *ngFor="let photo of photos;let i = index" class="photo-gallery">
    <!-- <div style="text-align: center;">{{photo.mediaItemMetaData.creationTime | date:'mediumDate'}}</div> -->
    <mat-checkbox [checked]="photos[i].selected" *ngIf="isEditMode || addAlbumMode" (change)="onSelect(i)">
    </mat-checkbox>
    <img *ngIf="photo.mediaType === 'photo'" 
      class="img-fluid gallery-image"
      style="border-radius: 10px; background-color: #c4c4c4;"
      [src]="photo.base64ThumbnailImage ? photo.base64ThumbnailImage : photo.base64CompressedImage" alt="could not load"
      (click)="openModal(photo, i)">

    <div class="overlay-container" *ngIf="photo.mediaType === 'video'">
      <img class="img-fluid gallery-image"
        style="border-radius: 10px; background-color: #c4c4c4;"
        [src]="photo.base64ThumbnailImage ? photo.base64ThumbnailImage : photo.base64CompressedImage"
        alt="could not load" (click)="openModal(photo, i)">
      <!-- <img *ngIf="isSmallScreen" class="img-fluid gallery-image"
      [ngClass]="{'edit-mode' : isAlbumMode || isEditMode}"
        style="border-radius: 10px; background-color: #c4c4c4;"
        [src]="photo.base64ThumbnailImage ? photo.base64ThumbnailImage : photo.base64CompressedImage"
        alt="could not load" (click)="playVideoMobile(photo, i)"> -->
      <!-- <video *ngIf="isSmallScreen" [src]="photo.videoSrc" class="img-fluid w-100" controls
        (click)="playVideo(photo)"></video> -->
      <mat-icon class="overlay-center" svgIcon="play_circle_outline" aria-hidden="false"
        aria-label="Example thumbs up SVG icon" (click)="openModal(photo, i)">
      </mat-icon>
    </div>
  </div>
</div>

<div class="center-spinner">
  <app-spinner [loading]="loading"></app-spinner>
</div>
<app-infinite-scroll></app-infinite-scroll>