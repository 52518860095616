"use strict";
/*@__PURE__*/ Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = ["@media (min-width:320px){.photo-gallery[_ngcontent-%COMP%]{max-width:90px;padding-top:3px;padding-left:6px}.edit-mode[_ngcontent-%COMP%]{height:40px}}@media (min-width:480px){.photo-gallery[_ngcontent-%COMP%]{max-width:120px;padding-top:3px;padding-left:6px}.edit-mode[_ngcontent-%COMP%]{height:40px}}@media (min-width:600px){.photo-gallery[_ngcontent-%COMP%]{min-width:240px;min-height:240px;padding-top:5px;padding-left:16px}}@media (min-width:801px){.photo-gallery[_ngcontent-%COMP%]{min-width:240px;min-height:240px;padding-top:5px;padding-left:16px}}@media (min-width:1025px){.photo-gallery[_ngcontent-%COMP%]{min-width:240px;min-height:240px;padding-top:5px;padding-left:16px}}@media (min-width:1281px){.photo-gallery[_ngcontent-%COMP%]{min-width:240px;min-height:240px;padding-top:5px;padding-left:16px}}html[_ngcontent-%COMP%]{-webkit-tap-highlight-color:transparent}.gallery-image[_ngcontent-%COMP%]{cursor:pointer}.opac[_ngcontent-%COMP%]{color:#000;opacity:.5!important}.mat-icon[_ngcontent-%COMP%]{width:60px;height:60px}.noselect[_ngcontent-%COMP%]{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.overlay-container[_ngcontent-%COMP%]{position:relative}.overlay-center[_ngcontent-%COMP%]{position:absolute;top:42%;left:42%;background-color:transparent;color:#fff;border:none;cursor:pointer;border-radius:5px}.nav-icon[_ngcontent-%COMP%]{width:40px;height:40px}.overlay-right[_ngcontent-%COMP%]{top:50%;border:none}.overlay-more[_ngcontent-%COMP%], .overlay-right[_ngcontent-%COMP%]{position:absolute;right:2%;background-color:transparent;color:#fff;cursor:pointer;border-radius:5px}.overlay-more[_ngcontent-%COMP%]{top:1%}"];
exports.styles = styles;

