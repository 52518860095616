<a name="about"></a>
<h1>About</h1>
<p>
 When you sign in, google will share your name and and with me. It is used to identify you when logging in.
 I won't share this data with others. There is also a feature to sync your google library photos with this
 app which requires you to grand access to the google photos library api.
</p>
<p>
 If you have any questions, you can ask me, 
  Be sure to include an email address so I know where to send the reply.
  kooriim@kooriim.com
 </p>

<a name="homepage"></a>
<h1>Google OAuth2 Homepage</h1>
<p>
 <u>Accurately represents your app's identity:</u> The now.kooriim.com App is  
 <a href="https://now.kooriim.com"></a> just a website to view and manage photos. It just lets you view content that you
 upload or download from your google photos library.
</p>
<p>
 <u>What will this app do with user data?</u> The only user data received is: name, email. These will be saved and re-displayed to the user.
 The email will be used to reply to messages sent by the user. The email will be used to identify the user. 
</p>
<p>
 <u>How does this app enhance user functionality?</u> This app just contains static content. Some content is only displayed to signed in users. The only enhancement of
 functionality is access to additional content.
</p>
<p>
 <u>Link to Privacy Policy:</u> <a href="https://now.kooriim.com/privacy-policy">Click here.</a>
</p>
<p>
 <u>Describe the content, context, or connection to the app:</u> The website is just a way to manage a users photos and videos.
</p>

<a name="limitedUse"></a>
<h1>Google OAuth2 Limited Use Disclosure</h1>
<p>
 This app doesn't request restricted scopes, but if it does use sensitive scope to allow kooriim to download 
 a users google library photos.
</p>

<a name="privacy"></a>
<h1>Privacy Policy</h1>
<p>
 Cookies are used 
 for login purposes. No information is given to other parties, except this site does include google analytics which
 records anonymized browsing behaviour. This site uses cookies as described below.
</p>
<p>
 <u>How does this application access, use, store, or share Google user data?</u> The only user data received is: name, email and library photos. 
 These are accessed via OAuth2 login. These are used only: to be re-displayed to the user, to reply
 to messages sent by the user, and as identification for granting access to additional content to the user. None of this data is shared with anyone.
</p>
<p>
 By continuing to use this site, you accept this privacy policy in full. If you disagree with this policy, 
 you must not use this site.
</p>

<a name="cookies"></a>
<h1>Cookies</h1>
<p>
 Cookies are short messages sent from your browser to websites you visit. 
 This site uses cookies. By using this site you consent to the use of cookies.
</p>
<p>
 This site uses cookies to identify who is logged in, and google analytics uses cookies to record anonymized browsing behaviour.
</p>

<a name="terms"></a>
<h1>Terms of Service</h1>
<p>
 This site is designed to educate and entertain. It's provided "as is", without any warranty or condition, express or implied or statutory. 
 This site specifically disclaims any implied warranties of merchantability or fitness for a particular purpose.
</p>
<p>
 By continuing to use this site, you accept these terms of service in full. If you disagree with these terms, 
 you must not use this site.
</p>