import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-gallery',
  templateUrl: './public-gallery.component.html',
  styleUrls: ['./public-gallery.component.scss']
})
export class PublicGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {    console.log('aa');

  }

}
