"use strict";
/*@__PURE__*/ Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [""];
exports.styles = styles;

