<button class="button-aliceblue" mat-stroked-button (click)="googleSync()">google-sync</button>&nbsp;
<mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input (dateInput)="startDateInput($event)" [min]="startMinDate" [max]="startMaxDate" matStartDate formControlName="start" placeholder="Start date">
    <input (dateInput)="endDateInput($event)" [min]="endMinDate" [max]="endMaxDate" matEndDate formControlName="end" placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>
