<div class='center-content'>
   <h6>KOORIIM</h6>
   <h3>Media management portal</h3>
   <div>
      This site requires you to
      grant access to your google photos so you can sync your google photos
      to kooriim. Login with your existing google account to create a user, and upload and 
      manage all your photos and videos using kooriim.
   </div>
</div>
<div class="center-screen">
   <img class='pointer' src='/assets/google-signin.png' onmouseover="this.src='/assets/google-signin-hover.png'"
      onmouseout="this.src='/assets/google-signin.png'" (click)="click()">
</div>