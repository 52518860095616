<div class="center-content">
        <div style="padding-left: 5%; padding-top: 1%; width: 1008px; height:268px; background-color: #1c7c7c; border-radius: 20px; color: #ffffff">
            <div style="padding-top: 40px; font-size: 24px; font-family: sans-serif;">Hello, {{userName}}!</div>
            <div style="padding-bottom: 25px;">Welcome to Kooriim, your photo management system.</div>
            <a href="file-upload">
                <button style="color: black;width: 192px; height: 44px; border-radius: 35px">
                    Add Photo
                </button>
            </a>
        </div>
        <!-- <img class='img-fluid background' src='/assets/home-background.png'> -->
</div>