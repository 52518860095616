<div class="container">
    <div class="row">
        <h1 class="col-md-9">{{albumMode}}</h1>
        <div *ngIf="albumMode === 'Edit album'" class="col-md-3 pointer" (click)="onDelete()">
            <mat-icon class="float-right" svgIcon="delete_sweep" aria-hidden="false" aria-label="Example thumbs up SVG icon">
            </mat-icon>
        </div>
    </div>
    <form #albumForum="ngForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="name">title</label>
            <input #titleForm="ngModel" type="text" class="form-control" id="title" [(ngModel)]="album.title"
                name="title" required maxlength="40">
            <div [hidden]="titleForm.valid || titleForm.pristine" class="alert alert-danger">
                Title is required
            </div>
        </div>

        <div class="form-group">
            <label for="description">Description</label>
            <input #descriptionForm="ngModel" type="text" class="form-control" id="description"
                [(ngModel)]="album.description" name="album.description" maxlength="42">
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-success">Submit</button>
            <div *ngIf="errorMessage" class="alert-danger">
                error: {{errorMessage}}
            </div>
        </div>
    </form>
</div>