<div class="center-content">
  <button mat-stroked-button (click)="createAlbum()">create Album</button>
</div>

<span style="padding-left: 10%;size: 20px; color: #2d2d2d;"><b>Albums</b></span>
<div class="row center-content">
  <div *ngFor="let album of albums;let i = index" class="card-row">
    <mat-card class="card">
      <mat-card-header>
        <div mat-card-avatar class="pointer" (click)="addPhotoToAlbum(i, album.title)">
          <mat-icon svgIcon="add_photo_to_album" aria-hidden="false" aria-label="add_photo_to_album" class="nav-icon">
          </mat-icon>
        </div>
        <mat-card-title class="card-title">
          <a [routerLink]="['/photo-gallery']" [queryParams]="{albumId:album.id, title:album.title}" queryParamsHandling="merge">
            <h6 class="center-content truncate-text"><b>{{album.title}}</b></h6>
          </a>
        </mat-card-title>
        <div class="pointer" (click)="createAlbum(album)">
          <mat-icon svgIcon="edit" aria-hidden="false" aria-label="edit" class="nav-icon">
          </mat-icon>
        </div>
      </mat-card-header>
      <mat-card-subtitle class="card-subtitle">
        {{album.description}}
      </mat-card-subtitle>
      <mat-card-content class="card-content">
        <div class="container">
          <div class="row justify-content-md-center col-xs-12 center-content">
            <div *ngFor="let photo of album.previewMediaItems;let j = index" class="col-xs-5">
              <img class="img-fluid preview-available" [src]="photo.base64ThumbnailImage">
            </div>
            <div *ngIf="album.previewMediaItems.length < 1" class="col-xs-5">
              <img class="img-fluid preview-unavailable" [src]="'/assets/album-preview.png'">
            </div>
            <div *ngIf="album.previewMediaItems.length <= 1" class="col-xs-5">
              <img class="img-fluid preview-unavailable" [src]="'/assets/album-preview.png'">
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="center-spinner">
  <app-spinner [loading]="loading"></app-spinner>
</div>