"use strict";
/*@__PURE__*/ Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".ng-valid.required[_ngcontent-%COMP%], .ng-valid[required][_ngcontent-%COMP%]{border-left:5px solid #42a948}.ng-invalid[_ngcontent-%COMP%]:not(form){border-left:5px solid #a94442}"];
exports.styles = styles;

