<nav class="navbar navbar-expand-lg navbar-light shadow-sm" style="height: 80px; background-color: rgb(230, 244, 244)">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContentModal"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div style="height: 100%" class="navbar-collapse collapse collapse-backdrop " id="navbarSupportedContentModal">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link pointer" (click)="close()">
          <mat-icon svgIcon="home" aria-hidden="false" aria-label="home" class="nav-icon" style="width: 35px">
          </mat-icon>
          <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link pointer" (click)="onDelete()">
          <mat-icon class="nav-icon" style="width: 35px" svgIcon="delete_sweep" aria-hidden="false"
            aria-label="Example thumbs up SVG icon">
          </mat-icon>
        </a>
      </li>
      <li class="nav-item">
        <button mat-stroked-button (click)="addToAlbum()">add to
          album</button>&nbsp;
      </li>
      <li class="nav-item">
        description: <input maxlength="50" style="width: 250px" [(ngModel)]="photos[index].description">
        <button (click)="updatePhotoDescription()">save</button>
      </li>
      <ul class="navbar-nav">
        <li class="nav-item">
          <div>
            <b>taken</b>: {{photos[index].originalDate | date:'mediumDate'}}
          </div>
          <div>
            <b>title</b>: {{photos[index].title}}
          </div>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <form class="form-inline my-2 my-lg-0 nav navbar-nav navbar-right">
            <button *ngIf="mediaType == 'photo'" class="btn btn-outline-success my-2 my-sm-0" type="submit"
              (click)="onOriginalImage()">Original Image</button>
          </form>
        </li>
      </ul>
    </ul>
  </div>
</nav>
<div class="overlay-container center-content" style="background-color: black;">
  <mat-icon class="overlay-left" (click)="onNavigate('previous')" [ngClass]="{'pointer': showPrevious===true}"
    *ngIf="showPrevious" svgIcon="navigate_before" aria-hidden="false" aria-label="Example thumbs up SVG icon">
  </mat-icon>
  <img *ngIf="mediaType == 'photo'" class="img-fluid" style="object-fit: contain;max-height: 800px" [src]="imgSrc"
    alt="could not load">
  <video *ngIf="mediaType === 'video'" class="img-fluid" [src]="videoSrc" style="object-fit: contain;max-height: 800px"
    controls></video>
  <mat-icon class="overlay-right" (click)="onNavigate('next')" [ngClass]="{'pointer': showNext===true}" *ngIf="showNext"
    svgIcon="navigate_next" aria-hidden="false" aria-label="Example thumbs up SVG icon">
  </mat-icon>
</div>
<div class="center-spinner">
  <app-spinner [loading]="loading"></app-spinner>
</div>