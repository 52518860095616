"use strict";
/*@__PURE__*/ Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".opac[_ngcontent-%COMP%]{color:#000;opacity:.5!important}.mat-icon[_ngcontent-%COMP%]{width:60px;height:60px}  .modal-dialog{max-width:90%}.noselect[_ngcontent-%COMP%]{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.overlay-container[_ngcontent-%COMP%]{position:relative}.overlay-left[_ngcontent-%COMP%]{left:2%}.overlay-left[_ngcontent-%COMP%], .overlay-right[_ngcontent-%COMP%]{position:absolute;top:50%;background-color:transparent;color:#fff;border:none;cursor:pointer;border-radius:5px;z-index:5}.overlay-right[_ngcontent-%COMP%]{right:2%}.overlay-more[_ngcontent-%COMP%]{position:absolute;top:1%;right:2%;background-color:transparent;color:#fff;cursor:pointer;border-radius:5px}.collapse-backdrop[_ngcontent-%COMP%]{z-index:100000!important;background-color:#e6f4f4}.vid[_ngcontent-%COMP%]{z-index:100}"];
exports.styles = styles;

