"use strict";
/*@__PURE__*/ Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
var styles = [".body[_ngcontent-%COMP%]{padding-bottom:170px}.background[_ngcontent-%COMP%]{padding-top:45%}"];
exports.styles = styles;

