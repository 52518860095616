<nav class="navbar navbar-expand-lg navbar-light border shadow-sm p-3 mb-3"
  style="background-color: rgb(230, 244, 244)">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">
          <mat-icon svgIcon="home" aria-hidden="false" aria-label="home" class="nav-icon">
          </mat-icon>
          <span class="sr-only">(current)</span>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="file-upload">
          <mat-icon svgIcon="add_photo" aria-hidden="false" aria-label="add_photo" class="nav-icon">
          </mat-icon>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="photo-gallery">
          <mat-icon svgIcon="photo" aria-hidden="false" aria-label="photo" class="nav-icon">
          </mat-icon>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="albums">
          <mat-icon svgIcon="photo_library" aria-hidden="false" aria-label="photo_library" class="nav-icon">
          </mat-icon>
        </a>
      </li>

      <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a> -->
      <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div> -->
      <!-- </li> -->
    </ul>
  </div>
  <!-- <span style="padding-right:1%;">Welcome {{userName}}!</span> -->
  <form class="form-inline my-2 my-lg-0">
    <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="onClick()">Logout</button>
  </form>
</nav>